import * as React from 'react';
import type {
  IStylableButtonCorvidStyleStateProps,
  StylableButtonAnimatedIcon,
} from '../StylableButton.types';
import AnimatedSvg from '../../AnimatedSvg/viewer/AnimatedSvg';
import type { AnimatedSvgRef } from '../../AnimatedSvg/AnimatedSvg.types';
import {
  extractSvgAttributes,
  getPathFromSvg,
} from '../../AnimatedSvg/viewer/utils';

export const createIconFromString = (svg: string) => {
  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: svg || '',
    },
  });
};

export const addPrefixToId = (svg: string, prefix: string): string => {
  if (!svg) {
    return svg;
  }

  return svg.replace(
    /(id="|url\(#|href="#)([^"]+)(?=[")])/g,
    (match, prefixOrUrlOrHref, id) => {
      return `${prefixOrUrlOrHref}${prefix + id}`;
    },
  );
};

const buildStyleStateKey = (key: string, state?: 'Hover' | 'Disabled') =>
  ['has', state, ...key.split('has').slice(1)].join('');

export function buildCorvidStyleStates({
  hover = {},
  disabled = {},
  ...regular
}: IStylableButtonCorvidStyleStateProps & {
  hover?: IStylableButtonCorvidStyleStateProps;
  disabled?: IStylableButtonCorvidStyleStateProps;
}) {
  return {
    ...regular,
    ...Object.fromEntries([
      ...Object.entries(hover).map(([key, value]) => [
        buildStyleStateKey(key, 'Hover'),
        value,
      ]),
      ...Object.entries(disabled).map(([key, value]) => [
        buildStyleStateKey(key, 'Disabled'),
        value,
      ]),
    ]),
  };
}

export const buildAnimatedIcon = ({
  svgStringAnimatedStart,
  svgStringAnimatedEnd,
  animationDuration,
  id,
  iconAnimatedRef,
  reducedMotion,
}: StylableButtonAnimatedIcon & {
  id: string;
  iconAnimatedRef: React.Ref<AnimatedSvgRef>;
  reducedMotion: boolean | undefined;
}) => {
  const pathStart = getPathFromSvg(svgStringAnimatedStart);
  const pathEnd = getPathFromSvg(svgStringAnimatedEnd);
  const attributes = extractSvgAttributes(
    addPrefixToId(svgStringAnimatedStart, id),
  );
  if (pathStart && pathEnd) {
    return (
      <AnimatedSvg
        pathStart={pathStart}
        pathEnd={pathEnd}
        attributes={attributes}
        duration={animationDuration}
        reducedMotion={reducedMotion}
        ref={iconAnimatedRef}
      />
    );
  }
  return null;
};
